import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Code`}</h1>
    <p><a parentName="p" {...{
        "href": "https://github.com/Uniswap/uniswap-v2-periphery/blob/master/contracts/libraries/UniswapV2Library.sol"
      }}><inlineCode parentName="a">{`UniswapV2Library.sol`}</inlineCode></a></p>
    <h1 {...{
      "id": "internal-functions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#internal-functions",
        "aria-label": "internal functions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Internal Functions`}</h1>
    <h2 {...{
      "id": "sorttokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#sorttokens",
        "aria-label": "sorttokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`sortTokens`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function sortTokens(address tokenA, address tokenB) internal pure returns (address token0, address token1);
`}</code></pre>
    <p>{`Sorts token addresses.`}</p>
    <h2 {...{
      "id": "pairfor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#pairfor",
        "aria-label": "pairfor permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`pairFor`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function pairFor(address factory, address tokenA, address tokenB) internal pure returns (address pair);
`}</code></pre>
    <p>{`Calculates the address for a pair without making any external calls (see `}<Link to='/docs/honeyswap/technical-considerations/pair-addresses' mdxType="Link">{`Pair Addresses`}</Link>{`).`}</p>
    <h2 {...{
      "id": "getreserves",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getreserves",
        "aria-label": "getreserves permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getReserves`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function getReserves(address factory, address tokenA, address tokenB) internal view returns (uint reserveA, uint reserveB);
`}</code></pre>
    <p>{`Calls `}<Link to='/docs/honeyswap/smart-contracts/pair#getreserves' mdxType="Link">{`getReserves`}</Link>{` on the pair for the passed tokens, and returns the results sorted in the order that the parameters were passed in.`}</p>
    <h2 {...{
      "id": "quote",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#quote",
        "aria-label": "quote permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`quote`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function quote(uint amountA, uint reserveA, uint reserveB) internal pure returns (uint amountB);
`}</code></pre>
    <p>{`Given some asset amount and reserves, returns an amount of the other asset representing equivalent value.`}</p>
    <ul>
      <li parentName="ul">{`Useful for calculating optimal token amounts before calling `}<Link to='/docs/honeyswap/smart-contracts/pair#mint-1' mdxType="Link">{`mint`}</Link>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "getamountout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getamountout",
        "aria-label": "getamountout permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getAmountOut`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function getAmountOut(uint amountIn, uint reserveIn, uint reserveOut) internal pure returns (uint amountOut);
`}</code></pre>
    <p>{`Given an `}<em parentName="p">{`input`}</em>{` asset amount, returns the maximum `}<em parentName="p">{`output`}</em>{` amount of the other asset (accounting for fees) given reserves.`}</p>
    <ul>
      <li parentName="ul">{`Used in `}<a parentName="li" {...{
          "href": "#getamountsout"
        }}>{`getAmountsOut`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "getamountin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getamountin",
        "aria-label": "getamountin permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getAmountIn`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function getAmountIn(uint amountOut, uint reserveIn, uint reserveOut) internal pure returns (uint amountIn);
`}</code></pre>
    <p>{`Returns the minimum `}<em parentName="p">{`input`}</em>{` asset amount required to buy the given `}<em parentName="p">{`output`}</em>{` asset amount (accounting for fees) given reserves.`}</p>
    <ul>
      <li parentName="ul">{`Used in `}<a parentName="li" {...{
          "href": "#getamountsin"
        }}>{`getAmountsIn`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "getamountsout",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getamountsout",
        "aria-label": "getamountsout permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getAmountsOut`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function getAmountsOut(uint amountIn, address[] memory path) internal view returns (uint[] memory amounts);
`}</code></pre>
    <p>{`Given an `}<em parentName="p">{`input`}</em>{` asset amount and an array of token addresses, calculates all subsequent maximum `}<em parentName="p">{`output`}</em>{` token amounts by calling `}<a parentName="p" {...{
        "href": "#getreserves"
      }}>{`getReserves`}</a>{` for each pair of token addresses in the path in turn, and using these to call `}<a parentName="p" {...{
        "href": "#getamountout"
      }}>{`getAmountOut`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">{`Useful for calculating optimal token amounts before calling `}<Link to='/docs/honeyswap/smart-contracts/pair#swap-1' mdxType="Link">{`swap`}</Link>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "getamountsin",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getamountsin",
        "aria-label": "getamountsin permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`getAmountsIn`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function getAmountsIn(uint amountOut, address[] memory path) internal view returns (uint[] memory amounts);
`}</code></pre>
    <p>{`Given an `}<em parentName="p">{`output`}</em>{` asset amount and an array of token addresses, calculates all preceding minimum `}<em parentName="p">{`input`}</em>{` token amounts by calling `}<a parentName="p" {...{
        "href": "#getreserves"
      }}>{`getReserves`}</a>{` for each pair of token addresses in the path in turn, and using these to call `}<a parentName="p" {...{
        "href": "#getamountin"
      }}>{`getAmountIn`}</a>{`.`}</p>
    <ul>
      <li parentName="ul">{`Useful for calculating optimal token amounts before calling `}<Link to='/docs/honeyswap/smart-contracts/pair#swap-1' mdxType="Link">{`swap`}</Link>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      